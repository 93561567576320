import { ProductOptionGroupRes, ShopRes, ShopRetrieveRes, ShopStatusEnum, SiteRes } from '@/api/generated/types'
import { OpenStatusEnum } from '@/constants/openStatus.enum'
import { DateUtils } from '@/utils/date'

const getShopStatus = (shop?: ShopRes | ShopRetrieveRes, site?: SiteRes) => {
  const isSiteBreakTime = DateUtils.getNowIsAble(site?.breakStartAt, site?.breakEndAt)
  const isSiteOpen = DateUtils.getNowIsAbleWithEmpty(site?.openAt, site?.lastOrderAt)
  const isShopOpen = DateUtils.getNowIsAbleWithEmpty(shop?.openAt, shop?.lastOrderAt)
  const isShopBreakTime = DateUtils.getNowIsAble(shop?.breakStartAt, shop?.breakEndAt)

  if (isSiteBreakTime) {
    return OpenStatusEnum.SiteBreakTime
  }
  // 가게 임시중지 인가?
  const isSuspended = shop?.shopStatus === ShopStatusEnum.SUSPENDED

  if (!isSiteOpen || !isShopOpen) {
    return OpenStatusEnum.NotOpenTime
  }
  if (isShopBreakTime) {
    return OpenStatusEnum.ShopBreakTime
  }
  if (isSuspended) {
    return OpenStatusEnum.Suspended
  }
  return OpenStatusEnum.Open
}

const isOptionRadioButton = (optionGroup: ProductOptionGroupRes) => {
  return optionGroup.minCount && optionGroup.maxCount && optionGroup.minCount === optionGroup.maxCount
}

const isFixedRadioButton = (optionGroup: ProductOptionGroupRes) => {
  return (
    optionGroup.minCount &&
    optionGroup.maxCount &&
    optionGroup.minCount === optionGroup.maxCount &&
    optionGroup.minCount > 1
  )
}

export const ShopUtils = {
  getShopStatus,
  isOptionRadioButton,
  isFixedRadioButton
}
