import classNames from 'classnames'
import { forwardRef, useState } from 'react'
import SkeletonBase from '@/components/common/skeleton/SkeletonBase'

type ImgProps = {
  className: string
  src?: string | null
  alt: string
  position?: 'absolute' | 'relative' | 'fixed'
  overlay?: boolean
}

const Img = forwardRef<HTMLDivElement, ImgProps>(
  ({ className, src, alt, position = 'relative', overlay = false }, ref) => {
    const [isLoaded, setIsLoaded] = useState(src ? true : false)

    return (
      <div className={classNames(position, className, 'overflow-hidden')} ref={ref}>
        {!isLoaded && <SkeletonBase className="h-full w-full" />}
        <img
          className={classNames('h-full w-full object-cover', !isLoaded && 'hidden')}
          src={src ?? '/images/neubie-placeholder.png'}
          alt={alt}
          onLoad={() => {
            setIsLoaded(true)
          }}
        />
        {overlay && <div className={classNames(className, 'absolute top-0 bg-gray-900/[0.05]')} />}
      </div>
    )
  }
)

Img.displayName = 'Img'

export default Img
