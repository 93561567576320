import { SiteDispatchStatusRes } from '@/api/generated/types'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import CTAButton from '@/components/common/design-system/CTAButton'
import StaticImg from '@/components/common/StaticImg'
import useBottomSheetLegacy from '@/hooks/common/useBottomSheetLegacy'
import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import { useEffect } from 'react'
import HomeContainer from '@/containers/pages/home/HomeContainer'

export type RobotStatusBottomSheetDataType = {
  robotStatus?: SiteDispatchStatusRes
}

const DscampBottomSheet = () => {
  const dscampBottomSheetControls = useBottomSheetLegacy()
  const { handleOpen, handleClose } = dscampBottomSheetControls
  const { siteSlug } = HomeContainer.useContainer()

  useEffect(() => {
    if (siteSlug === 'dscamp') {
      handleOpen()
    }
  }, [siteSlug])

  return (
    <BottomSheetLegacy bottomSheetControls={dscampBottomSheetControls} background="bg-primary-100">
      <div className="min-h-40 w-full" />
      <div className="flex w-full flex-col items-center justify-center">
        <span className="title4 whitespace-pre-line text-center font-bold">
          {"‘대관령 솔내음 캠피장'의\n로봇 배달 서비스를 준비 중이에요"}
        </span>
        <span className="body3 mt-8 w-full text-center font-medium text-gray-700">
          {'서비스가 시작되면 안내 드릴게요. 곧 만나요!'}
        </span>
        <div className="h-12 w-full" />
        <StaticImg className="h-150 w-150" src="/images/home-robot-status.png" alt="뉴비그림" />
      </div>
      <div className="h-30 w-full" />
      <CTAButton divider={false} background="bg-primary-100">
        <Button full theme={ButtonThemeEnum.Primary} size={ButtonSizeEnum.ExtraLarge} onClick={() => handleClose()}>
          <span className="body1 font-bold">확인했어요!</span>
        </Button>
      </CTAButton>
    </BottomSheetLegacy>
  )
}

export default DscampBottomSheet
