import { CSCallNumber, SiteShopManagerPhoneNumber } from '@/constants/order'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'

export const getSiteCallNumber = (siteSlug: SiteSlugEnum) => {
  return CSCallNumber[siteSlug] || CSCallNumber[SiteSlugEnum.Neubie]
}

const getSiteShopManagerPhoneNumber = (siteSlug: SiteSlugEnum) => {
  return SiteShopManagerPhoneNumber[siteSlug] || CSCallNumber[SiteSlugEnum.Neubie]
}

const isDeliPickMe = (siteSlug: SiteSlugEnum | string) => {
  return [SiteSlugEnum.RaemianLeadersone, SiteSlugEnum.AnyangCcDrivingRange].some((slug) => slug === siteSlug)
}

const SiteUtils = {
  getSiteCallNumber,
  getSiteShopManagerPhoneNumber,
  isDeliPickMe
}

export default SiteUtils
