import { CompanyInfo } from '@/constants/companyInfo'
import { PolicyTypeEnum } from '@/components/policy/PolicyBottomSheet'

const neubilityTextInfo = (
  `상호 : ${CompanyInfo.name}\n` +
  `대표 : ${CompanyInfo.representativeName}\n` +
  `사업자등록번호 : ${CompanyInfo.businessRegistrationNumber}\n` +
  `주소 : ${CompanyInfo.address}\n` +
  `대표전화 : ${CompanyInfo.telephoneNumber}\n` +
  `이메일: ${CompanyInfo.email}`
).split('\n')

type HomeFooterProps = {
  handleOpenPolicy(type: PolicyTypeEnum): void
}

const HomeFooter = ({ handleOpenPolicy }: HomeFooterProps) => {
  return (
    <footer className="flex w-full flex-col bg-gray-50 px-16 pt-20 pb-40">
      <div className="mb-20 ">
        <button
          onClick={() => handleOpenPolicy(PolicyTypeEnum.ServiceTerms)}
          className="caption border-r-1 border-gray-300 pr-8 font-medium text-gray-700">
          서비스 이용약관
        </button>
        <button
          onClick={() => handleOpenPolicy(PolicyTypeEnum.FinancialDealTerms)}
          className="caption border-r-1 border-gray-300 px-8 font-medium text-gray-700">
          전자금융거래 이용약관
        </button>
        <button
          onClick={() => handleOpenPolicy(PolicyTypeEnum.PrivacyPolicy)}
          className="caption pl-8 font-medium text-gray-700">
          개인정보처리방침
        </button>
      </div>
      <div className="flex flex-col">
        {neubilityTextInfo.map((word) => (
          <span key={word} className="caption font-medium text-gray-500">
            {word}
          </span>
        ))}
        <span className="caption font-medium text-gray-500">
          뉴빌리티 및 뉴비오더는 통신판매중개자로, 통신 판매의 당사자가 아니며, 거래에 대한 책임은 상기 전자금융거래
          이용약관을 준수하고 있습니다.
        </span>
      </div>
    </footer>
  )
}

export default HomeFooter
