import { OrderExpectWaitStatusEnum, SiteDispatchStatusRes, SiteRes } from '@/api/generated/types'
import Icon from '@/components/common/Icon'
import StaticImg from '@/components/common/StaticImg'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'
import { isSite } from '@/pages/_app'
import { DateUtils } from '@/utils/date'
import classNames from 'classnames'

type RobotsStatusInfo = {
  robotStatus?: SiteDispatchStatusRes
  site?: SiteRes
}

const getDisabledRobotInfoDetailText = ({ robotStatus, site }: RobotsStatusInfo) => {
  const isSiteClose = !DateUtils.getNowIsAble(site?.openAt, site?.lastOrderAt)
  const isSiteBreakTime = DateUtils.getNowIsAble(site?.breakStartAt, site?.breakEndAt)

  if (isSiteClose) {
    return '현재 영업시간이 아니에요'
  }
  if (isSiteBreakTime) {
    return isSite(SiteSlugEnum.Secsuwon) ? '정비를 위해 배달을 잠시 중단할게요' : '현재 영업시간이 아니에요'
  }
  if (robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.UNAVAILABLE) {
    return '정비를 위해 배달을 잠시 중단할게요'
  }
  return '배달이 밀려서 대기 시간이 필요해요'
}

const RobotsStatusInfo = ({ robotStatus, site }: RobotsStatusInfo) => {
  const isAvailable = robotStatus?.orderExpectWaitStatus === OrderExpectWaitStatusEnum.AVAILABLE

  return (
    <section className="relative w-full">
      <div className="h-130 w-full">
        <div className="relative flex h-85 w-full items-center rounded-t-sm bg-primary-300 pl-20">
          <div className="flex flex-col">
            {isAvailable ? (
              <>
                <span className="title3 font-bold text-white">지금은 뉴비가</span>
                <span className="title3 font-bold text-white">배달 가능해요!</span>
              </>
            ) : (
              <>
                <span className="title3 font-bold text-white">지금은 임무를</span>
                <span className="title3 font-bold text-white">받을 수 없어요</span>
              </>
            )}
          </div>
          <div className="absolute right-102 h-85">
            <span className="text-72 font-bold leading-86 text-white">{robotStatus?.orderCanDispatchCount}</span>
          </div>
          <div className="robot-info-gradient absolute right-97 top-10 h-65 w-30 " />
          <StaticImg
            className="bottom-8 right-28 h-89 w-68"
            src="/images/home-robot.png"
            alt="홈 로봇 정보 뉴비 이미지"
            position="absolute"
          />
        </div>
        {isAvailable ? (
          <div
            className={classNames(
              'flex h-45 w-full items-center justify-between rounded-b-sm bg-primary-700 px-16 text-info'
            )}>
            <div className="flex items-center gap-4">
              <Icon name={IconNamesEnum.Information} className="h-16 w-16" />
              <span className="body3">지금 배달 요청 시 완료까지</span>
            </div>
            {!!robotStatus?.orderExpectArrivedSec && (
              <span className="body3 font-bold">{`최대 ${DateUtils.secToMin(
                robotStatus?.orderExpectArrivedSec
              )}분`}</span>
            )}
          </div>
        ) : (
          <div
            className={classNames('flex h-45 w-full items-center gap-4 rounded-b-sm bg-primary-200 px-16 text-white')}>
            <Icon name={IconNamesEnum.Information} className="h-16 w-16" />
            <span className={classNames('body3')}>{getDisabledRobotInfoDetailText({ site, robotStatus })}</span>
          </div>
        )}
      </div>
    </section>
  )
}

export default RobotsStatusInfo
