import { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'
import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import StaticImg from '@/components/common/StaticImg'
import { isSite } from '@/pages/_app'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'

type LoadInfoBottomSheetProps = {
  bottomSheetControls: BottomSheetLegacyControlsType
}

const LoadInfoBottomSheetContent = ({ bottomSheetControls }: LoadInfoBottomSheetProps) => {
  return (
    <>
      <BottomSheetLegacy.Header bottomSheetControls={bottomSheetControls} isShowCloseButton>
        뉴비 적재량
      </BottomSheetLegacy.Header>
      <div className="mt-13 overflow-y-scroll px-16 scrollbar-hide">
        <div className="flex w-full gap-1 overflow-hidden rounded-sm">
          {isSite(SiteSlugEnum.Secsuwon) ? (
            <>
              <StaticImg className="h-full w-full" src="/images/load-image-1.png" alt="적재 용량 확인 이미지" />
              <StaticImg className="h-full w-full" src="/images/load-image-5.png" alt="적재 용량 확인 이미지" />
              <StaticImg className="h-full w-full" src="/images/load-image-3.png" alt="적재 용량 확인 이미지" />
            </>
          ) : (
            <>
              <StaticImg className="h-full w-full" src="/images/load-image-1.png" alt="적재 용량 확인 이미지" />
              <StaticImg className="h-full w-full" src="/images/load-image-3.png" alt="적재 용량 확인 이미지" />
              <StaticImg className="h-full w-full" src="/images/load-image-4.png" alt="적재 용량 확인 이미지" />
            </>
          )}
        </div>

        <div className="mt-8 flex flex-col gap-8 py-8">
          <span className="body3 font-medium before:mr-5 before:content-['•']">
            적재함 가로 360mm x 세로 330mm x 높이 350mm (보냉백 미포함)
          </span>
          <div className="mt-16 mb-8 flex w-full items-center justify-center rounded-xs bg-warning/10 py-8">
            <span className="body3 text-center font-medium text-warning">아래 적재 예시는 참고용이에요!</span>
          </div>
          {isSite(SiteSlugEnum.Secsuwon) ? (
            <div className="flex flex-col gap-14">
              <div className="flex flex-col gap-8">
                <div className="flex items-center gap-8">
                  <StaticImg
                    src="/images/파리바게트-로고.png"
                    className="h-40 w-40 rounded-xs border border-gray-100"
                    alt="파리바게트 로고"
                  />
                  <span className="body2 font-medium text-gray">파리바게트</span>
                </div>
                <span className="body3 font-medium before:mr-5 before:content-['•']">롤케이크 9개 (최대 수량)</span>
                <span className="body3 font-medium before:mr-5 before:content-['•']">
                  1호 케이크 1개 + 롤케이크 2개 + 커피 6잔 + 여유 공간
                </span>
                <span className="body3 font-medium before:mr-5 before:content-['•']">
                  종이봉투(대) 1개 + 커피 6잔 + 여유 공간
                </span>
                <span className="body3 font-medium before:mr-5 before:content-['•']">
                  음료 10잔 + 여유 공간 (음료 10잔 이상 적재 불가)
                </span>
              </div>
              <div className="flex flex-col gap-8">
                <div className="flex items-center gap-8">
                  <StaticImg
                    src="/images/드립앤더치-로고.png"
                    className="h-40 w-40 rounded-xs border border-gray-100"
                    alt="드립앤더치-로고"
                  />
                  <span className="body2 font-medium text-gray">드립앤더치</span>
                </div>
                <span className="body3 font-medium before:mr-5 before:content-['•']">
                  음료 10잔 + 여유 공간 (음료 10잔 이상 적재 불가)
                </span>
              </div>
              <div className="flex flex-col gap-8">
                <div className="flex items-center gap-8">
                  <StaticImg
                    src="/images/판도라마트-로고.png"
                    className="h-40 w-40 rounded-xs border border-gray-100"
                    alt="판도라마트-로고"
                  />
                  <span className="body2 font-medium text-gray">판도라마트</span>
                </div>
                <span className="body3 font-medium before:mr-5 before:content-['•']">판도라마트 대형 쇼핑백 2개</span>
              </div>
            </div>
          ) : (
            <>
              <span className="body3 font-medium before:mr-5 before:content-['•']">
                파리바게트 1호 케이크 1개 + 롤케이크 2개 + 커피 6잔
              </span>
              <span className="body3 font-medium before:mr-5 before:content-['•']">
                파리바게트 종이봉투(대) 1개 + 커피 6잔
              </span>
              <span className="body3 font-medium before:mr-5 before:content-['•']">
                탕용기 3개 + 2L페트병 혹은 캔음료4개
              </span>
              <span className="body3 font-medium before:mr-5 before:content-['•']">찜용기 4개 + 2L페트병 1개</span>
              <span className="body3 font-medium before:mr-5 before:content-['•']">도시락용기 8개 + 캔음료 6개</span>
              <span className="body3 font-medium before:mr-5 before:content-['•']">죽용기 8개 + 캔음료 6개</span>
            </>
          )}
        </div>
        <div className="h-[calc(env(safe-area-inset-top)+2.4rem)] w-full" />
      </div>
    </>
  )
}

const LoadInfoBottomSheet = ({ bottomSheetControls }: LoadInfoBottomSheetProps) => {
  return (
    <BottomSheetLegacy bottomSheetControls={bottomSheetControls}>
      <LoadInfoBottomSheetContent bottomSheetControls={bottomSheetControls} />
    </BottomSheetLegacy>
  )
}

export default LoadInfoBottomSheet
