import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Img from '@/components/common/Img'
import { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'

type BadWeatherBottomSheetProps = {
  bottomSheetControls: BottomSheetLegacyControlsType
}

const BadWeatherBottomSheetContent = () => {
  return (
    <>
      <Img className="w-full" src="/images/home-rain.png" alt="비 그림" />
      <div className="h-[env(safe-area-inset-bottom)]" />
    </>
  )
}

const BadWeatherBottomSheet = ({ bottomSheetControls }: BadWeatherBottomSheetProps) => {
  return (
    <BottomSheetLegacy bottomSheetControls={bottomSheetControls} background="bg-primary-300">
      <BadWeatherBottomSheetContent />
    </BottomSheetLegacy>
  )
}

export default BadWeatherBottomSheet
