import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Button, { ButtonSizeEnum, ButtonThemeEnum } from '@/components/common/design-system/Button'
import CTAButton from '@/components/common/design-system/CTAButton'
import DropDown, { DropDownDirectionEnum } from '@/components/common/design-system/DropDown'
import Divider from '@/components/common/Divider'
import { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'
import useDropDown from '@/hooks/common/useDropDown'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

export enum PolicyTypeEnum {
  ServiceTerms = 'serviceTerms',
  FinancialDealTerms = 'financialDealTerms',
  PrivacyPolicy = 'privacyPolicy'
}

type PolicyBottomSheetProps = {
  bottomSheetControls: BottomSheetLegacyControlsType<PolicyBottomSheetDataType>
}

export type PolicyBottomSheetDataType = {
  selectedPolicyType: PolicyTypeEnum
}

type HTMLMarkupType = {
  __html: string
}

type PolicyInfoType = {
  title: string
  filename: `${string}.html`
}

const policyTypeMap = new Map<PolicyTypeEnum, PolicyInfoType[]>([
  [
    PolicyTypeEnum.PrivacyPolicy,
    [
      { title: '[현행] 2024년 06월 24일 시행안', filename: 'privacyPolicy-240624.html' },
      { title: '2024년 03월 04일 시행안', filename: 'privacyPolicy-240304.html' },
      {
        title: '2023년 06월 12일 시행안',
        filename: 'privacyPolicy-230612.html'
      }
    ]
  ],
  [
    PolicyTypeEnum.FinancialDealTerms,
    [{ title: '[현행] 2023년 06월 12일 시행안', filename: 'financialDealTerms-230612.html' }]
  ],
  [
    PolicyTypeEnum.ServiceTerms,
    [
      { title: '[현행] 2024년 05월 24일 시행안', filename: 'serviceTerms-240524.html' },
      { title: '2023년 06월 12일 시행안', filename: 'serviceTerms-230612.html' }
    ]
  ]
])

const PolicyBottomSheetContent = ({ bottomSheetControls }: PolicyBottomSheetProps) => {
  const { bottomSheetData, setBottomSheetData, handleClose } = bottomSheetControls
  const { selectedPolicyType } = bottomSheetData
  const scrollBoxRef = useRef<HTMLDivElement>(null)
  const policyDivRef = useRef<HTMLDivElement>(null)
  const [policyMarkup, setPolicyMarkup] = useState<HTMLMarkupType>()
  const dropDownProps = useDropDown()

  const getPolicyData = async (filename: string) => {
    const data = await axios.get(`/policy/${filename}`)
    const markupText = data.data
    setPolicyMarkup({ __html: markupText })
  }

  useEffect(() => {
    if (selectedPolicyType === PolicyTypeEnum.PrivacyPolicy && scrollBoxRef.current) {
      scrollBoxRef.current.scroll({ behavior: 'smooth', left: 500 })
    }
    const policyInfos = policyTypeMap.get(selectedPolicyType)
    if (policyInfos) {
      const options = policyInfos.map((policy) => ({ id: policy.filename, content: policy.title }))
      dropDownProps.setOptionList(options)
      dropDownProps.setSelectedItem(options[0])
    }
  }, [selectedPolicyType])

  useEffect(() => {
    const loadPolicy = async () => {
      if (dropDownProps.selectedItem) {
        await getPolicyData(dropDownProps.selectedItem.id)
        policyDivRef.current && policyDivRef.current.scrollTo({ behavior: 'smooth', top: 0 })
      }
    }
    loadPolicy()
  }, [dropDownProps.selectedItem])

  return (
    <>
      <BottomSheetLegacy.Header bottomSheetControls={bottomSheetControls}>이용약관</BottomSheetLegacy.Header>
      <div
        ref={scrollBoxRef}
        className="flex min-h-64 w-full flex-nowrap gap-8 overflow-y-hidden overflow-x-scroll scroll-smooth px-16 pt-12 pb-16 scrollbar-hide">
        <Button
          theme={ButtonThemeEnum.Chip}
          className="body3"
          active={selectedPolicyType === PolicyTypeEnum.ServiceTerms}
          onClick={() => {
            setBottomSheetData({ selectedPolicyType: PolicyTypeEnum.ServiceTerms })
            scrollBoxRef.current?.scroll({ behavior: 'smooth', left: 0 })
          }}>
          서비스 이용약관
        </Button>
        <Button
          theme={ButtonThemeEnum.Chip}
          className="body3"
          active={selectedPolicyType === PolicyTypeEnum.FinancialDealTerms}
          onClick={(e) => {
            setBottomSheetData({ selectedPolicyType: PolicyTypeEnum.FinancialDealTerms })
            e.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'center' })
          }}>
          전자금융거래 이용약관
        </Button>
        <Button
          theme={ButtonThemeEnum.Chip}
          className="body3"
          active={selectedPolicyType === PolicyTypeEnum.PrivacyPolicy}
          onClick={() => {
            setBottomSheetData({ selectedPolicyType: PolicyTypeEnum.PrivacyPolicy })
            scrollBoxRef.current?.scroll({ behavior: 'smooth', left: 500 })
          }}>
          개인정보처리방침
        </Button>
      </div>
      <Divider />
      <div className="w-full px-16 pt-16 pb-32">
        <DropDown
          defaultItemText={dropDownProps.selectedItem?.content || ''}
          dropDownProps={dropDownProps}
          direction={DropDownDirectionEnum.Down}
          isResetSelection={false}>
          <DropDown.List>
            {dropDownProps.optionList?.map((option) => {
              return (
                <DropDown.Item
                  dropDownProps={dropDownProps}
                  key={option.id}
                  itemContent={{
                    id: option.id,
                    content: <span>{option.content}</span>
                  }}
                />
              )
            })}
          </DropDown.List>
        </DropDown>
      </div>
      <div ref={policyDivRef} className="w-full overflow-y-scroll px-16">
        <div dangerouslySetInnerHTML={policyMarkup} />
      </div>
      <CTAButton divider={false}>
        <Button theme={ButtonThemeEnum.Primary} size={ButtonSizeEnum.ExtraLarge} full onClick={() => handleClose()}>
          <span className="body1 font-bold">확인</span>
        </Button>
      </CTAButton>
    </>
  )
}

const PolicyBottomSheet = ({ bottomSheetControls }: PolicyBottomSheetProps) => {
  return (
    <BottomSheetLegacy bottomSheetControls={bottomSheetControls} height="h-[80vh]">
      <PolicyBottomSheetContent bottomSheetControls={bottomSheetControls} />
    </BottomSheetLegacy>
  )
}

export default PolicyBottomSheet
