import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import StaticImg from '@/components/common/StaticImg'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'
import useBottomSheetLegacy from '@/hooks/common/useBottomSheetLegacy'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import dayjs from 'dayjs'
import { useEffect } from 'react'

const TeheranServiceCloseBottomSheet = () => {
  const bottomSheetControls = useBottomSheetLegacy(true)
  useEffect(() => {
    const siteSlug = LocalStorage.getItem<string>(LocalStorageKeyEnum.SiteSlug)
    if (
      siteSlug === SiteSlugEnum.Teheran &&
      dayjs().isBetween(dayjs('2023-12-25 00:00:00'), dayjs('2024-01-01 23:59:59'), null, '[]')
    ) {
      bottomSheetControls.handleOpen()
    }
  }, [])
  return (
    <BottomSheetLegacy bottomSheetControls={bottomSheetControls}>
      <div className="flex w-full justify-center">
        <StaticImg className="rounded-t-md" src="/images/bottom-sheet/teheran-pause.jpg" alt="바텀시트 뉴비그림" />
      </div>
    </BottomSheetLegacy>
  )
}

export default TeheranServiceCloseBottomSheet
