import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { Routes } from '@/constants/routes'
import Icon from '@/components/common/Icon'
import { IconNamesEnum } from '@/constants/iconNames.enum'

/**
 * 디자인 시스템을 따르는 바텀 네비게이션 바 입니다.
 */
const NavigationBar = () => {
  const { pathname } = useRouter()
  const isHistoryTab = [Routes.OrderHistory, Routes.OrderHistoryDocument].includes(pathname)

  return (
    <>
      <nav className="fixed bottom-0 z-header flex h-[calc(env(safe-area-inset-bottom)+5.7rem)] w-full max-w-body items-start border-t-1 border-t-gray-100 bg-white py-8">
        <Link
          href={Routes.Home}
          className={classNames('flex w-full flex-col items-center justify-center', {
            'text-primary': pathname === Routes.Home
          })}>
          <Icon name={pathname === Routes.Home ? IconNamesEnum.HomeFilledTrue : IconNamesEnum.HomeFilledFalse} />
          <span className="caption font-medium">홈</span>
        </Link>
        <Link
          href={Routes.OrderHistory}
          className={classNames('flex w-full flex-col items-center justify-center', {
            'text-primary': isHistoryTab
          })}>
          <Icon name={isHistoryTab ? IconNamesEnum.OrderListFilledTrue : IconNamesEnum.OrderListFilledFalse} />
          <span className="caption font-medium">이용내역</span>
        </Link>
        <Link
          href={Routes.MyPage}
          className={classNames('flex w-full flex-col items-center justify-center', {
            'text-primary': pathname === Routes.MyPage
          })}>
          <Icon name={pathname === Routes.MyPage ? IconNamesEnum.SmileFilledTrue : IconNamesEnum.SmileFilledFalse} />
          <span className="caption font-medium">마이페이지</span>
        </Link>
      </nav>
      <div className="h-[calc(env(safe-area-inset-bottom)+5.7rem)] w-full max-w-body" />
    </>
  )
}

export default NavigationBar
