import { SiteSlugEnum } from '@/constants/siteSlug.enum'

export const CSCallNumber: { [key in SiteSlugEnum]: string } = {
  [SiteSlugEnum.Secsuwon]: '010-7586-9619',
  [SiteSlugEnum.KonkukSeoul]: '010-5763-9659',
  [SiteSlugEnum.Neubie]: '1600-9620',
  [SiteSlugEnum.Teheran]: '010-2917-0950',
  [SiteSlugEnum.RaemianLeadersone]: '1600-9620',
  [SiteSlugEnum.AnyangCcDrivingRange]: '1600-9620'
}

export const SiteShopManagerPhoneNumber: { [key in SiteSlugEnum]: string } = {
  [SiteSlugEnum.Secsuwon]: '',
  [SiteSlugEnum.KonkukSeoul]: '',
  [SiteSlugEnum.Neubie]: '',
  [SiteSlugEnum.Teheran]: '',
  [SiteSlugEnum.RaemianLeadersone]: '',
  [SiteSlugEnum.AnyangCcDrivingRange]: '010-6351-2066'
}
