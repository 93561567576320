import { DeliveryTypesEnum, ShopRes } from '@/api/generated/types'
import Badge from '@/components/common/design-system/Badge'
import NavigationBar from '@/components/common/design-system/NavigationBar'
import Toast from '@/components/common/design-system/Toast'
import DeliveryTypeTap from '@/components/common/header/DeliveryTypeTap'
import Header, { SkeletonHeader } from '@/components/common/header/Header'
import Icon from '@/components/common/Icon'
import InfiniteContainer from '@/components/common/infinite/InfiniteContainer'
import SkeletonBase from '@/components/common/skeleton/SkeletonBase'
import BadWeatherBottomSheet from '@/components/home/BadWeatherBottomSheet'
import HomeFooter from '@/components/home/HomeFooter'
import HomeShopCard, { SkeletonHomeCardList } from '@/components/home/HomeShopCard'
import HomeTopSlimBanner from '@/components/home/HomeTopSlimBanner'
import LoadInfoBottomSheet from '@/components/home/LoadInfoBottomSheet'
import RobotsStatusInfo from '@/components/home/RobotsStatusInfo'
import RobotStatusBottomSheet from '@/components/home/RobotStatusBottomSheet'
import ServiceFinishBottomSheet from '@/components/home/ServiceFinishBottomSheet'
import SiteMapBottomSheet from '@/components/home/SiteMapBottomSheet'
import TeheranServiceCloseBottomSheet from '@/components/home/TeheranServiceCloseBottomSheet'
import BaseHeader, { HeaderTypeHeightEnum } from '@/components/layouts/BaseHeader'
import MainBody from '@/components/layouts/MainBody'
import LoadingFallback from '@/components/LoadingFallback'
import PolicyBottomSheet from '@/components/policy/PolicyBottomSheet'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { Routes } from '@/constants/routes'
import HomeContainer from '@/containers/pages/home/HomeContainer'
import ScrollRestoreContainer from '@/containers/ScrollRestoreContainer'
import Link from 'next/link'
import GeoMapContainer from '@/containers/common/GeoMapContainer'
import DscampBottomSheet from '@/components/home/DscampBottomSheet'
import SiteUtils from '@/utils/site'
import SiteBottomSheet from '@/components/home/SiteBottomSheet'
import useSiteBottomSheet from '@/hooks/home/useSiteBottomSheet'

const getBlankDivHeight = (isRenderDeliveryTap: boolean, isRaemianLeadersOne: boolean) => {
  if (isRaemianLeadersOne) {
    return HeaderTypeHeightEnum.None
  }
  return isRenderDeliveryTap ? HeaderTypeHeightEnum.HeaderDeliveryTap : HeaderTypeHeightEnum.Header
}

const PageHomeContent = () => {
  const {
    policyBottomSheetControls,
    robotStatusBottomSheetControls,
    loadInfoBottomSheetControls,
    siteNodeDropDownControls,
    siteMapBottomSheetControls,
    badWeatherBottomSheetControls,
    secSuwonServiceFinishBottomSheetControls,
    teheranServiceFinishBottomSheetControls,
    toastControls,
    promiseSiteName,
    handleSavePromiseNode,
    handleSiteMapOpen,
    handleOpenPolicy,
    handleOpenLoadInfo,
    handleDeliveryTap,
    shopList,
    fetchNextShopList,
    site,
    siteSlug,
    cartCount,
    robotStatus,
    nodeList
  } = HomeContainer.useContainer()

  const isRenderDeliveryTap = !(
    site?.deliveryTypes?.find((type) => type === DeliveryTypesEnum.FOOD) && site?.deliveryTypes.length === 1
  )
  const isDeliPickMe = !!site?.slug && SiteUtils.isDeliPickMe(site.slug)
  const blankDivHeight = getBlankDivHeight(isRenderDeliveryTap, isDeliPickMe)

  const siteBottomSheetControls = useSiteBottomSheet({ siteSlug })

  return (
    <>
      <MainBody>
        <LoadingFallback isLoading={!site} fallback={<SkeletonHeader />}>
          {isDeliPickMe && (
            <div className="flex h-50 items-center justify-center">
              <img
                className="h-34"
                src="/images/home-top-banner-raemian-leadersone.png"
                alt="raemian-leadersone-banner"
              />
            </div>
          )}
          <BaseHeader className={isDeliPickMe ? 'sticky' : ''} blankDivHeight={blankDivHeight}>
            {isRenderDeliveryTap && (
              <DeliveryTypeTap deliveryTypes={site?.deliveryTypes ?? []} handleDeliveryTap={handleDeliveryTap} />
            )}
            <Header
              center={
                promiseSiteName && (
                  <button className="flex w-full justify-center" onClick={handleSiteMapOpen}>
                    <Icon name={IconNamesEnum.LocationFilledFalse} className="h-24 w-24" />
                    <h1 className="body1 truncate font-bold">{promiseSiteName}</h1>
                  </button>
                )
              }
              rightSide={
                <Link href={Routes.Cart} className="relative flex h-full w-full items-center justify-end">
                  <Icon name={IconNamesEnum.Basket} className="h-24 w-24" />
                  {!!cartCount && (
                    <Badge
                      content={String(cartCount)}
                      className="absolute top-[-1rem] right-[-1rem] bg-primary text-white"
                    />
                  )}
                </Link>
              }
            />
          </BaseHeader>
        </LoadingFallback>
        <HomeTopSlimBanner onClick={handleOpenLoadInfo} />
        <div className="px-16">
          <div className="h-36 w-full" />
          <LoadingFallback isLoading={!robotStatus} fallback={<SkeletonBase className="h-130 w-full rounded-sm" />}>
            <RobotsStatusInfo robotStatus={robotStatus} site={site} />
          </LoadingFallback>
          <div className="h-24 w-full" />
          <div className="body2 flex gap-2 text-gray-500">
            <LoadingFallback isLoading={!site?.name} fallback={<SkeletonBase className="h-22 w-160 rounded-xss" />}>
              <span>{site?.name}</span>
              <span className="font-bold">{shopList?.pages[0].count}</span>
            </LoadingFallback>
          </div>
          <div className="h-12 w-full" />
          <LoadingFallback isLoading={!shopList || !site} fallback={<SkeletonHomeCardList />}>
            <InfiniteContainer<ShopRes>
              className="flex flex-col gap-22"
              threshold={0.5}
              scrollData={shopList}
              fetchNextPage={fetchNextShopList}>
              {({ itemRes }) => {
                return <HomeShopCard shop={itemRes} site={site} />
              }}
            </InfiniteContainer>
          </LoadingFallback>
          <div className="h-40 w-full" />
        </div>
        <HomeFooter handleOpenPolicy={handleOpenPolicy} />
        <NavigationBar />
      </MainBody>
      <PolicyBottomSheet bottomSheetControls={policyBottomSheetControls} />
      <LoadInfoBottomSheet bottomSheetControls={loadInfoBottomSheetControls} />
      <RobotStatusBottomSheet bottomSheetControls={robotStatusBottomSheetControls} />
      <BadWeatherBottomSheet bottomSheetControls={badWeatherBottomSheetControls} />
      <SiteMapBottomSheet
        bottomSheetControls={siteMapBottomSheetControls}
        dropDownControls={siteNodeDropDownControls}
        handleSavePromiseNode={handleSavePromiseNode}
        nodeList={nodeList}
      />
      <ServiceFinishBottomSheet
        bottomSheetControls={secSuwonServiceFinishBottomSheetControls}
        siteSlug={siteSlug}
        texts={['로봇배송 실증서비스는', '11월 10일(금요일)부로 종료되었으며,', '후속 운영을 위해 별도협의 중입니다.']}
        testsEmphasis={['뉴빌리티와 로봇사업팀에', '많은 관심 가져주셔서 감사합니다.']}
      />
      <ServiceFinishBottomSheet
        bottomSheetControls={teheranServiceFinishBottomSheetControls}
        siteSlug={siteSlug}
        texts={[
          '강남 테헤란로 로봇배송 실증서비스는',
          '1월 31일(수요일)부로 종료되었습니다.',
          '뉴빌리티 X KT 로봇배송 서비스를',
          '관심있게 지켜봐 주시고 이용해주셔서 감사드립니다.'
        ]}
        testsEmphasis={['이후 더 나은 모습으로', '찾아올 예정이니 관심갖고 지켜봐주세요.']}
      />

      {/*  todo 추후 건국대, 테헤란로 바텀시트 컴포넌트 제거 */}
      {/* <KonkukServiceCloseBottomSheet /> */}
      <TeheranServiceCloseBottomSheet />
      {/* TODO: 대관령 솔내음 */}
      <DscampBottomSheet />
      <SiteBottomSheet {...siteBottomSheetControls} />
      <Toast toastControls={toastControls} />
    </>
  )
}

const PageHome = () => {
  return (
    <ScrollRestoreContainer.Provider>
      <GeoMapContainer.Provider>
        <HomeContainer.Provider>
          <PageHomeContent />
        </HomeContainer.Provider>
      </GeoMapContainer.Provider>
    </ScrollRestoreContainer.Provider>
  )
}

export default PageHome
