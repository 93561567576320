import classNames from 'classnames'

type TagProps = {
  content: string | number
  className: string
}

const Tag = ({ content, className }: TagProps) => {
  return (
    <div
      className={classNames('caption flex h-25 items-center justify-center rounded-4 px-8 py-4 font-bold', className)}>
      {content}
    </div>
  )
}

export default Tag
