import BottomSheetLegacy from '@/components/common/design-system/BottomSheetLegacy'
import Icon from '@/components/common/Icon'
import StaticImg from '@/components/common/StaticImg'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { BottomSheetLegacyControlsType } from '@/hooks/common/useBottomSheetLegacy'
import { DateUtils } from '@/utils/date'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'

type ServiceFinishBottomSheetProps = ServiceFinishBottomSheetContentProps & {
  bottomSheetControls: BottomSheetLegacyControlsType
  siteSlug?: string
}

type ServiceFinishBottomSheetContentProps = {
  texts?: string[]
  testsEmphasis?: string[]
}

export type ServiceFinishHideOnlyTodayInfoType = {
  [siteSlug: string]: string
}

const ServiceFinishBottomSheetContent = ({ texts, testsEmphasis }: ServiceFinishBottomSheetContentProps) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col items-center p-32">
        <div className="pb-16">
          <StaticImg className="h-150 w-150" src="/images/home-service-finish.png" alt="바텀시트 뉴비그림" />
        </div>
        {texts?.map((text, index) => (
          <span key={index} className="body2">
            {text}
          </span>
        ))}
        {testsEmphasis?.map((text, index) => (
          <span key={index} className={`body1 font-bold ${index === 0 && 'pt-8'}`}>
            {text}
          </span>
        ))}
        <div className="mt-8 flex w-full flex-col gap-4 overflow-auto"></div>
      </div>
    </div>
  )
}

const ServiceFinishBottomSheet = ({
  bottomSheetControls,
  siteSlug,
  ...contentProps
}: ServiceFinishBottomSheetProps) => {
  if (!siteSlug) {
    return null
  }

  return (
    <BottomSheetLegacy
      bottomSheetControls={bottomSheetControls}
      topSide={
        <div className={'mb-16 flex items-center justify-between px-bodySide'}>
          <button
            className="flex items-center gap-4"
            onClick={() => {
              const hideOnlyTodayInfo =
                LocalStorage.getItem<ServiceFinishHideOnlyTodayInfoType>(
                  LocalStorageKeyEnum.ServiceFinishHideOnlyToday
                ) ?? {}
              hideOnlyTodayInfo[siteSlug] = DateUtils.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')
              LocalStorage.setItem<ServiceFinishHideOnlyTodayInfoType>(
                LocalStorageKeyEnum.ServiceFinishHideOnlyToday,
                hideOnlyTodayInfo
              )
              bottomSheetControls.handleClose()
            }}>
            <Icon name={IconNamesEnum.Close} className="h-24 w-24 text-white" />
            <span className="body2 font-medium text-white">오늘 하루 보지 않기</span>
          </button>
          )
          <button className="body2 font-medium text-white" onClick={bottomSheetControls.handleClose}>
            닫기
          </button>
        </div>
      }>
      <ServiceFinishBottomSheetContent {...contentProps} />
    </BottomSheetLegacy>
  )
}

export default ServiceFinishBottomSheet
