import Icon from '@/components/common/Icon'
import { IconNamesEnum } from '@/constants/iconNames.enum'

type HomeTopSlimBannerProps = {
  onClick(): void
}

const HomeTopSlimBanner = ({ onClick }: HomeTopSlimBannerProps) => {
  return (
    <div
      className="flex h-36 w-full cursor-pointer items-center justify-center gap-4 bg-warning/10 text-warning"
      onClick={onClick}>
      <span className="body3 font-medium">뉴비가 한 번에 담을 수 있는 수량을 확인하세요</span>
      <Icon name={IconNamesEnum.ChevronRight} className="h-20 w-20" />
    </div>
  )
}

export default HomeTopSlimBanner
