import classNames from 'classnames'
import { HeaderTypeHeightEnum } from '@/components/layouts/BaseHeader'
import { useRouter } from 'next/router'
import { Routes } from '@/constants/routes'
import { DeliveryTypesEnum } from '@/api/generated/types'

type DeliveryTypeTapProps = {
  deliveryTypes: DeliveryTypesEnum[]
  handleDeliveryTap: (deliveryType: DeliveryTypesEnum) => void
}

const deliveryTypeLink: { [key in DeliveryTypesEnum]: string } = {
  DOCUMENT: Routes.DeliveryDocument,
  FOOD: Routes.Home
}

const getDeliveryTypeText = (deliveryType: DeliveryTypesEnum) => {
  if (deliveryType === DeliveryTypesEnum.FOOD) {
    return '음식 배달'
  }
  if (deliveryType === DeliveryTypesEnum.DOCUMENT) {
    return '문서 배달'
  }
  return ''
}

const DeliveryTypeTap = ({ deliveryTypes, handleDeliveryTap }: DeliveryTypeTapProps) => {
  const { pathname } = useRouter()

  return (
    <ul className="flex w-full">
      {deliveryTypes?.map((type) => {
        const isActive = pathname === deliveryTypeLink[type]
        return (
          <div
            key={type}
            className={classNames(
              HeaderTypeHeightEnum.DeliveryTap,
              'flex w-full cursor-pointer items-center justify-center bg-white',
              isActive ? 'border-b-2 border-gray-900' : 'border-b-1 border-gray-100'
            )}
            onClick={() => handleDeliveryTap(type)}>
            <h1 className={classNames('body1 text-center font-bold', isActive ? 'text-gray-800' : 'text-gray-300')}>
              {getDeliveryTypeText(type)}
            </h1>
          </div>
        )
      })}
    </ul>
  )
}

export default DeliveryTypeTap
