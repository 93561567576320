import { ShopRes, SiteRes } from '@/api/generated/types'
import Icon from '@/components/common/Icon'
import Img from '@/components/common/Img'
import SkeletonBase from '@/components/common/skeleton/SkeletonBase'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { OpenStatusEnum } from '@/constants/openStatus.enum'
import { RouteKeys, Routes } from '@/constants/routes'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'
import { isSite } from '@/pages/_app'
import { DateUtils } from '@/utils/date'
import { ShopUtils } from '@/utils/shop'
import classNames from 'classnames'
import Link from 'next/link'
import Tag from '@/components/common/design-system/Tag'

type HomeShopCardProps = {
  shop: ShopRes
  site?: SiteRes
}

const dimShopStatusText: { [key in string]: string } = {
  [OpenStatusEnum.NotOpenTime]: '현재 영업시간이 아니에요',
  [OpenStatusEnum.Suspended]: '영업 준비중이에요',
  [OpenStatusEnum.SiteBreakTime]: '정비를 위해 배달을\n잠시 중단할게요.',
  [OpenStatusEnum.ShopBreakTime]: isSite(SiteSlugEnum.Secsuwon)
    ? '정비를 위해 배달을\n잠시 중단할게요.'
    : '영업 준비중이에요'
}

const HomeShopCard = ({ shop, site }: HomeShopCardProps) => {
  const shopStatus = ShopUtils.getShopStatus(shop, site)
  const { name, id, openAt, lastOrderAt, mainImage } = shop

  return (
    <div className={classNames('flex w-full flex-col gap-8')}>
      <Link
        href={{
          pathname: Routes.Shop.Index,
          query: {
            [RouteKeys.ShopId]: id
          }
        }}>
        <div className="relative h-160 w-full overflow-hidden rounded-sm shadow-md">
          {(shopStatus === OpenStatusEnum.SiteBreakTime || shopStatus !== OpenStatusEnum.Open) && (
            <div className="absolute top-0 z-cardDim flex h-full w-full flex-col items-center justify-center rounded-sm bg-black/70">
              {dimShopStatusText[shopStatus].split('\n').map((word) => (
                <span key={word} className="body2 font-medium text-white">
                  {word}
                </span>
              ))}
            </div>
          )}
          {shop.hasEvent && (
            <Tag className="absolute top-12 left-12 z-cardTag bg-secondary text-white" content="이벤트 진행 중" />
          )}
          <Img
            className="h-full w-full"
            src={mainImage.fullSize ?? '/images/placeholder-main.png'}
            alt="홈 샵 이미지"
            overlay
          />
        </div>
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <span className="body1 font-bold text-gray-800">{name}</span>
          <div className="body3 flex items-center gap-2 text-gray-400">
            <Icon name={IconNamesEnum.Clock} className="h-16 w-16" />
            <span>{`${DateUtils.removeSeconds(openAt)}~${DateUtils.removeSeconds(lastOrderAt)}`}</span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default HomeShopCard

export const SkeletonHomeCardList = () => {
  return (
    <ul className="flex flex-col gap-22">
      <li className={classNames('flex w-full flex-col gap-8')}>
        <SkeletonBase className="h-160 w-full rounded-sm" />
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <SkeletonBase className="h-25 w-210 rounded-xss" />
          <SkeletonBase className="h-25 w-100 rounded-xss" />
        </div>
      </li>
      <li className={classNames('flex w-full flex-col gap-8')}>
        <SkeletonBase className="h-160 w-full rounded-sm" />
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <SkeletonBase className="h-25 w-210 rounded-xss" />
          <SkeletonBase className="h-25 w-100 rounded-xss" />
        </div>
      </li>
      <li className={classNames('flex w-full flex-col gap-8')}>
        <SkeletonBase className="h-160 w-full rounded-sm" />
        <div className="mt-5 flex h-25 items-center justify-between px-4">
          <SkeletonBase className="h-25 w-210 rounded-xss" />
          <SkeletonBase className="h-25 w-100 rounded-xss" />
        </div>
      </li>
    </ul>
  )
}
